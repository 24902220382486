<template>
  <div class="about">
    <div class="about__top" v-if="textBlock && textBlock['about_us']">
      <div class="container">
        <div class="row">
          <div class="about__title">
            <u-animate-container>
              <u-animate
                  name="fadeIn"
                  delay="0s"
                  duration="1s"
                  :iteration="1"
                  :offset="0"
                  animateClass="animate__fadeInUp"
                  :begin="false"
              >
                {{textBlock['about_us']['title']}}
              </u-animate>
            </u-animate-container>
          </div>
          <div class="about__text">

            <u-animate-container>
              <u-animate
                  name="fadeIn"
                  delay="0s"
                  duration="1s"
                  :iteration="1"
                  :offset="0"
                  animateClass="animate__fadeInUp"
                  :begin="false"
              >
                <p v-html="textBlock['about_us']['description']"></p>
              </u-animate>
            </u-animate-container>
          </div>
        </div>
      </div>
    </div>
    <div class="container">
      <div class="about__list row" v-if="aboutUsBlocks">
        <div class="about__block col-md-4" v-for="(item, index) in aboutUsBlocks.data" :key="index">
          <u-animate-container>
            <u-animate
                name="fadeIn"
                delay="0s"
                duration="1s"
                :iteration="1"
                :offset="0"
                animateClass="animate__fadeInUp"
                :begin="false"
            >
              <div class="about__item">
            <div class="about__item-title">
              {{item.name}}
            </div>
            <div class="about__item-text">
              {{item.message}}
            </div>
          </div>

            </u-animate>
          </u-animate-container>
        </div>
      </div>
      <div class="d-flex justify-center">
        <u-animate-container>
          <u-animate
              name="fadeIn"
              delay="0s"
              duration="1s"
              :iteration="1"
              :offset="0"
              animateClass="animate__fadeInUp"
              :begin="false"
          >
            <button class="btn yellow" @click="changeToggleRegistration(true)">Join us</button>
          </u-animate>
        </u-animate-container>
      </div>
    </div>
  </div>
</template>
<script>
import {UAnimateContainer, UAnimate} from 'vue-wow'
import 'animate.css';
import {mapGetters, mapMutations} from "vuex";
export default {
  components: {
    UAnimateContainer,
    UAnimate
  },

  data(){
    return{}
  },
  computed:{
    ...mapGetters({
      textBlock:'landing/textBlock',
      aboutUsBlocks: 'landing/aboutUsBlocks'
    }),
  },
  methods:{
    ...mapMutations({
      changeToggleRegistration: 'general/changeToggleRegistration',
    })
  }
}
</script>
<style lang="scss" scoped>
@import "src/assets/styles/styles";
.about{
  padding: 0 0 100px;
  position: relative;
  &:before{
    content: '';
    position: absolute;
    pointer-events: none;
    right: 0;
    top: -2%;
    height: 550px;
    width: 300px;
    max-width: 15%;
    background-image: url("~@/assets/images/about-bg_2.png");
    background-size: contain;
    background-position: right top;
    background-repeat: no-repeat;
    z-index: 1;
    @media screen and (max-width: 1340px) {
      display: none;
    }
  }
  &:after{
    content: '';
    position: absolute;
    pointer-events: none;
    left: 0%;
    top: 25%;
    height: 400px;
    width: 250px;
    max-width: 12%;
    background-image: url("~@/assets/images/about-bg_1.png");
    background-size: contain;
    background-position: left top;
    background-repeat: no-repeat;
    z-index: 1;
    @media screen and (max-width: 1340px) {
      display: none;
    }
  }
  &__top{
    padding-top: 70px;
    background: $grey;
    color: white;
    padding-bottom: 20px;
    position: relative;
    @media screen and (max-width: 720px){
      padding: 40px 20px;
    }
    &:after{
      content:'';
      position: absolute;
      left: 0;
      top: 100%;
      height: 150px;
      width: 100%;
      background-color: $grey;
    }
  }
  &__title{
    font-family: 'Mont', Arial;
    font-size: 96px;
    font-weight: 900;
    @media screen and (max-width: 1220px){
      font-size: 66px;
    }
    @media screen and (max-width: 720px){
      font-size: 46px;
    }
  }
  &__text{
    font-family: 'Inter', Arial;
    font-size: 16px;
    font-weight: 400;
    line-height: 21px;
    margin-bottom: 20px;
  }
  &__list{
    margin-bottom: 50px;
  }
  &__block{
    &:first-child{
      .about__item{
        background: linear-gradient(to bottom, white 70%, transparent);
      }
      &:before{
        content: '';
        position: absolute;
        right: 75%;
        top: 75%;
        height: 250px;
        width: 250px;
        border-radius: 50%;
        background-color: $white-grey;
        pointer-events: none;
        @media screen and (max-width: 1340px) {
          display: none;
        }
      }
    }
    &:nth-child(2){
      .about__item{
        background: linear-gradient(to bottom, white 70%, transparent);
      }
    }
    &:nth-child(3){
      .about__item{
       background: linear-gradient(to bottom, white 70%, transparent);
      }
      &:after{
        content: '';
        position: absolute;
        left: 55%;
        top: 80%;
        height: 250px;
        width: 250px;
        border-radius: 50%;
        background-color: $white-grey;
        pointer-events: none;
        @media screen and (max-width: 1340px) {
          display: none;
        }
      }
    }
  }
  &__item{
    padding: 50px 35px;
    border-radius: 27px;
    box-shadow: 0px 0px 11px rgba(59, 64, 240, 0.1);
    min-height: 350px;
    position: relative;
    z-index: 1;
    margin-bottom: 30px;
    @media screen and (max-width: 991px){
      margin-bottom: 20px;
      min-height: auto;
    }
  }
  &__item-title{
    font-family: 'Mont', Arial;
    font-size: 24px;
    font-weight: 900;
    line-height: 29px;
    text-transform: uppercase;
    min-height: 60px;
    margin-bottom: 30px;
  }
  &__item-text{
    font-family: 'Inter', Arial;
    font-size: 16px;
    font-weight: 400;
    line-height: 21px;
    max-height:160px;
    overflow: hidden;
  }
}
</style>
